<template>
  <div class="intent-examples">
    <div
      style="display: flex; align-items: center; min-height: 40px"
      class="add-example"
      v-show="!returnDisableAddExample()"
    >
      <div class="button-language" style="display: flex; flex-wrap: wrap">
        <el-button
          v-for="(value, name, index) in getExamples()"
          :key="`button${index}`"
          style="margin: 5px"
          size="medium"
          @click="setCurrentCheckedExamples(value, name)"
        >
          {{ getNameCountry(name) }}</el-button
        >
        <div class="input-with-icon" style="margin: 5px">
          <div v-if="country">
            <el-autocomplete
              class="inline-input"
              v-model="country"
              :fetch-suggestions="querySearch"
              placeholder=""
              @select="handleSelect"
              style="width: 100px"
            ></el-autocomplete>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-tooltip class="item" effect="dark" content="Add language" placement="right">
            <i style="margin-left: 5px" class="el-icon-plus" @click="setOpenInput"></i>
          </el-tooltip>
          <div v-if="isOpenInput && !country" class="list-country">
            <div
              v-for="({ value, link }, i) in listCountry"
              :key="`country-${i}`"
              @click="setCountryCode(value, link)"
              class="list-country-item"
            >
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-row :gutter="10" class="intent-examples-row">
      <el-col :span="14">
        <div class="wrap-input-area">
          <el-input
            :disabled="returnDisableInput()"
            v-model="exampleInput"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6 }"
            placeholder="Create a new example/ topic or choose one from below"
          />
          <p :class="[returnClassStyleCount(), 'input-count']">
            {{ returnWordLimit() }}
          </p>
        </div>
      </el-col>
      <el-col :span="10">
        <el-button
          :loading="isLoading"
          :disabled="returnDisableButton()"
          style="width: 100%; margin-top: 8px"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="addExampleForIntent(exampleInput, addExampleMessage)"
          >Add Examples</el-button
        ></el-col
      >
    </el-row>
    <el-row v-if="checkedExamples.length > 0">
      <el-radio-group v-model="publishedExample" style="margin-top: 5px; width: 100%">
        <el-table :data="checkedExamples" style="width: 100%" max-height="250">
          <el-table-column label="User examples">
            <template slot-scope="scope">
              <div class="example-radio" style="text-align: start">
                <div
                  @click="addExampleForIntent(scope.row.example, updateExampleMessage)"
                  class="example-radio-item"
                >
                  <el-radio :label="scope.row.example">
                    {{ captializeFirstLetter(scope.row.example) }}
                  </el-radio>
                </div>
                <el-tag style="margin-left: 10px" v-if="scope.row.published">Trending topic</el-tag>
                <el-button
                  class="button-delete-example"
                  type="danger"
                  icon="el-icon-delete"
                  :loading="isLoading"
                  @click="removeExample(scope.row.example)"
                  circle
                ></el-button>
              </div> </template
          ></el-table-column>
        </el-table>
      </el-radio-group>
    </el-row>
  </div>
</template>

<script>
import { gql } from "@apollo/client/core";
import {
  capitalizeFirstLetter,
  prepareExampleToSave,
  getNameCountryFromExample,
} from "@/helperMethods/trendingTopics/methods";
import { mixin } from "@/helperMethods/trendingTopics/mixins";
import _ from "lodash";
import { DEFAULT_LANGUAGE_TRENDING_TOPIC } from "@/helperMethods/trendingTopics/countryList";
export default {
  props: ["intentEdit", "resetInput", "handleEditModel"],
  mixins: [mixin],
  data() {
    return {
      publishedExample: "",
      maxLengthInput: 90,
      exampleInput: "",
      isLoading: false,
      country: "",
      countryKey: "",
      listCountry: [],
      isOpenInput: false,
      checkedExamples: [],
      addExampleMessage: {
        success: "Add example successfully",
        failed: "Failed to add example.",
      },
      updateExampleMessage: {
        success: "Update example successfully",
        failed: "Failed to update example.",
      },
      // trainingDataset: [],
    };
  },
  async created() {
    window.addEventListener("click", (e) => {
      if (
        !e.target.className.includes("list-country-item") &&
        !e.target.className.includes("el-icon-plus")
      ) {
        this.isOpenInput = false;
      }
    });
    // const { data } = await this.$apollo.query({
    //   query: gql`
    //     query {
    //       faqAPI {
    //         dataset: getDataset
    //       }
    //     }
    //   `,
    // });
    // console.log("asasas");
    // this.trainingDataset = data?.faqAPI?.dataset || [];
    // console.log(this.trainingDataset);
  },
  mounted() {
    const { examples } = this.intentEdit;
    if (examples && !_.isEmpty(JSON.parse(examples))) {
      const exampleByIntent = JSON.parse(examples);
      this.countryKey = Object.keys(exampleByIntent)[0];
      this.checkedExamples = exampleByIntent[this.countryKey].examples;
      this.publishedExample = this.checkedExamples.find((example) => example.published).example;
    }
    const modules = this.$store.state.modules;
    const settingTrendingTopicLanguages =
      modules.webchat?.trendingTopicConfig?.trendingTopicLanguages || [];

    if (!settingTrendingTopicLanguages || settingTrendingTopicLanguages.length === 0) {
      this.listCountry = DEFAULT_LANGUAGE_TRENDING_TOPIC;
    } else {
      this.listCountry = [...settingTrendingTopicLanguages].map(({ lang, value }) => ({
        link: lang,
        value,
      }));
    }
  },
  methods: {
    selectExamplesByDataAndCountry() {
      const { intentId, examples = "" } = this.intentEdit;
      const { data, intentName = "" } = intentId ? JSON.parse(intentId) : "";
      if (data && intentName) {
        const trainingDataset = _.get(this.$store.state, "training.dataset", []);
        const examplesByData = trainingDataset.find(
          (training) => training.intentName === intentName
        );
        const variations = [...examplesByData?.variations];
        if (!examples) {
          const objectExample = {};
          this.listCountry.forEach(({ link }) => {
            const exampleAtLink = {
              examples: variations
                .filter((variation) => variation.language === link)
                .map((variation) => ({ example: variation.text, published: false })),
              answer: "",
            };
            if (exampleAtLink.examples.length > 0) {
              objectExample[link] = exampleAtLink;
            }
          });
          this.intentEdit.examples = JSON.stringify(objectExample);
        } else {
          const currentExamples = JSON.parse(examples);
          this.listCountry.forEach(({ link }) => {
            if (currentExamples[link]) {
              const examplesAtLink = variations
                .filter(
                  (variation) =>
                    variation.language === link &&
                    !currentExamples[link].examples.some(
                      (currentExample) => currentExample.example === variation.text
                    )
                )
                .map((set) => ({ example: set.text, published: false }));
              currentExamples[link].examples.push(...examplesAtLink);
            } else {
              const exampleAtLink = {
                examples: variations
                  .filter((variation) => variation.language === link)
                  .map((variation) => ({ example: variation.text, published: false })),
                answer: "",
              };
              if (exampleAtLink.examples.length > 0) {
                currentExamples[link] = exampleAtLink;
              }
            }
          });
        }
      }
    },
    checkExistIntentEdit(object) {
      return _.isEmpty(object);
    },
    setCurrentCheckedExamples(value, name) {
      this.countryKey = name;
      this.checkedExamples = value.examples.sort((a, b) => b.published - a.published);
      this.publishedExample = [...this.checkedExamples].find(
        (example) => example.published
      )?.example;
      this.exampleInput = "";
    },
    getNameCountry(countryCode) {
      return getNameCountryFromExample(countryCode);
    },
    getExamples() {
      const { examples } = this.intentEdit;
      if (!examples) return {};
      return JSON.parse(examples);
    },
    captializeFirstLetter(string) {
      return capitalizeFirstLetter(string);
    },
    examplesTrending() {
      const { examples } = this.intentEdit;
      if (!examples) return [];
      return JSON.parse(examples);
    },
    returnWordLimit() {
      return `${this.exampleInput.length}/${this.maxLengthInput}`;
    },
    returnClassStyleCount() {
      const length = this.exampleInput.length;
      if (length === 0) return "";
      if (length > 0 && length <= this.maxLengthInput) return " typing";
      return "over-limit";
    },
    returnDisableButton() {
      const length = this.exampleInput.length;
      return length == 0 || length > this.maxLengthInput || !this.countryKey;
    },
    returnDisableAddExample() {
      return _.isEmpty(this.intentEdit);
    },
    returnDisableInput() {
      return _.isEmpty(this.intentEdit) || !this.countryKey;
    },
    async removeExample(example) {
      const { id, examples } = this.intentEdit;
      const exampleRecord = JSON.parse(examples);
      this.isLoading = true;
      if (this.checkedExamples.length === 1) {
        delete exampleRecord[this.countryKey];
      } else {
        const exampleToSave = this.checkedExamples.filter(
          (current) => current?.example !== example
        );
        exampleRecord[this.countryKey].examples = exampleToSave;
      }
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int, $examples: JSON) {
              saveExamplesForTopic(id: $id, examples: $examples)
            }
          `,
          variables: {
            id,
            examples: exampleRecord,
          },
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "error",
            message: "Failed to remove example.",
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          showClose: true,
          message: "Remove example successfully",
          type: "success",
        });
      }
      this.isLoading = false;
      await this.reCallApi();
      this.exampleInput = "";
      this.checkedExamples = exampleRecord[this.countryKey]
        ? exampleRecord[this.countryKey].examples.sort((a, b) => b.published - a.published)
        : [];
    },
    async addExampleForIntent(exampleInput, exampleMessage) {
      const { id, examples } = this.intentEdit;
      const examplesToSave = prepareExampleToSave(this.countryKey, exampleInput, examples);
      this.isLoading = true;
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int, $examples: JSON) {
              saveExamplesForTopic(id: $id, examples: $examples)
            }
          `,
          variables: {
            id,
            examples: examplesToSave,
          },
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "error",
            message: exampleMessage.failed,
          });
          return false;
        });
      if (isSaved) {
        this.$message({
          showClose: true,
          message: exampleMessage.success,
          type: "success",
        });
      }
      this.isLoading = false;
      this.isOpenInput = false;
      await this.reCallApi();
      this.exampleInput = "";
      this.country = "";
      this.checkedExamples = examplesToSave[this.countryKey].examples.sort(
        (a, b) => b.published - a.published
      );
      this.publishedExample = [...this.checkedExamples].find(
        (example) => example.published
      )?.example;
      this.exampleInput = this.publishedExample;
    },
    handleSelect(item) {
      this.countryKey = item.link;
    },
    querySearch(queryString, cb) {
      const links = this.listCountry;
      const results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    setCountryCode(country, countryKey) {
      this.country = country;
      this.countryKey = countryKey;
    },
    setOpenInput() {
      this.isOpenInput = true;
    },
  },
  watch: {
    intentEdit: function () {
      this.selectExamplesByDataAndCountry();
      this.exampleInput = "";
      this.checkedExamples = [];
      this.countryKey = "";
      this.country = "";
      this.isOpenInput = false;
      const { examples } = this.intentEdit;
      if (examples && !_.isEmpty(JSON.parse(examples))) {
        const exampleByIntent = JSON.parse(examples);
        this.countryKey = Object.keys(exampleByIntent)[0];
        this.checkedExamples = exampleByIntent[this.countryKey].examples;
        this.publishedExample = this.checkedExamples.find((example) => example.published)?.example;
      }
    },
    countryKey: function () {
      this.selectExamplesByDataAndCountry();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/colors.scss";

.list-country {
  max-height: 260px;
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  z-index: 999;
  right: -170px;
  width: 150px;
  background: white;
  border: solid 1px #80808052;
  > div {
    font-weight: 600;
    color: $color-dark;
    padding: 10px 0px 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    &:hover {
      background: $color-light;
      color: $color-danger;
    }
  }
  & {
    scrollbar-width: auto;
    scrollbar-color: #a1a1a100 #ffffff00;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 500px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-dark;
    border-radius: 30px;
    border: 4px none $color-light;
  }
}
.input-with-icon {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  > div {
    > i {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      cursor: pointer;
    }
  }
}
.intent-examples {
  .intent-examples-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .wrap-input-area {
      position: relative;
    }
    > div {
      position: relative;
      .input-count {
        position: absolute;
        right: 0px;
        bottom: -35px;
        font-size: 14px;
        color: gray;
        z-index: 99;
        &.typing {
          color: $color-success;
        }
        &.over-limit {
          color: $color-danger;
        }
      }
    }
  }
}
.example-radio {
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;
  .button-delete-example {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  &-item {
    width: calc(100% - 165px);
    word-break: break-word;
    > label {
      display: flex;
      white-space: normal;
      align-items: center;
    }
  }
}
</style>
